@media screen and (min-device-width:768px){
   body{
    overflow-y: hidden;
    background-color: rgb(242, 242, 242) !important;
   } 
   /* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
   
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(255, 0, 140); 
   
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }
   .loginWrapper{
    margin-top: 7%;
    display: flex;
    margin-left: 10%;
   }
   .loginBanner{
    height: 420px;
    width: 700px;
    object-fit: cover;
   }
   .accountIcon{
    width: 100px;
    height: 100px;
    margin-top: 3%;
   }
   .loginRight{
    background-color: white;
    width: 500px;
   }
   #standard-basic{
    width: 400px;
   }
   .loginTitle{
    color: rgb(49, 49, 49);
   }
   .loginButton{
    background-color:#ff0090;
    padding: 8px 55px 8px 55px;
    border: none;
    font-size: 17px;
    color: white;
    margin-top: 6%;
    transition-duration: 0.4s;
   }
   .loginButton:hover{
    background-color:#920253 ;
   }
   .forgot{
    color: rgb(131, 131, 131);
    font-size: 13px;
    text-decoration: none;
    margin-left: 60%;
    transition-duration: 0.4s;
   }
   .forgot:hover{
    color: blue;

   }
}
@media screen and (max-device-width:468px){
    body{
        
        background-color: rgb(242, 242, 242) !important;
       } 
    .accountIcon{
        width: 100px;
        height: 100px;
        margin-top: 3%;
        
       }
       .loginRight{
        background-color: white;

        
       }
       .loginBanner{
        height: 300px;
        width: 415px;
        object-fit: cover;
       }
       .inputDiv{
        align-items: left;
       
        
       }
       #standard-basic{
        width: 400px;
        
        
       }
       .loginTitle{
        text-align: left;
        font-size: 18px;
        margin-left: 5%;
        color: rgb(49, 49, 49);
       }
       .loginButton{
        background-color:#ff0090;
        padding: 8px 55px 8px 55px;
        border: none;
        font-size: 17px;
        color: white;
        margin-top: 6%;
        transition-duration: 0.4s;
       
       }
       .loginButton:hover{
        background-color:#920253 ;
       }
       .forgot{
        color: rgb(131, 131, 131);
        font-size: 13px;
        text-decoration: none;
        margin-left: 60%;
        transition-duration: 0.4s;
       }
       .forgot:hover{
        color: blue;
    
       }
}