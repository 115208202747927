.tableHeader{
    display: flex;
    margin-left: 1%;
    background-color: red;
    height: 40px;
    width: 98%;
    margin-top: 5%;
}
.tableHeader p{
    margin-left: 7%;
    font-weight: 500;
}
.tableData{
    display: flex;
}
.tableData p{
    margin-left: 7%;
    
}
.adminme{
    height: 100vh;
    overflow-y: scroll;
}