@media screen and (min-device-width:768px){
    
    .storesTable{
        margin-top: 4%;
        margin-left: 5%;
        width: 75%;
    }
    .storesTitle{
        font-size: 18px;
        font-weight: 500;
        color: #424242;
        text-align: left;
        
        width: 100%;
    
    }
    .addButton{
        display: flex;
        margin-bottom: 3%;
    }

    .addStoreButton{
        background-color: rgb(56, 97, 174);
        border: none;
        padding: 2px 8px 2px 8px;
        color: white;
        margin-top: 4%;
       
    }
    #standard-basic2{
        width: 300px;
        font-size: 13px;
    }
    .addStoreButton2{
        background-color: rgb(165, 31, 31);
        border: none;
        padding: 2px 8px 2px 8px;
        color: white;
        margin-top: 4%;
        margin-left: 6%;
    }
    .editBTN{
        color: rgb(255, 174, 24) !important;
        background-color: white;
        border: none;
        size: 3px;
        font-size: 12px !important;
    }
    .blockBTN{
        color: rgb(18, 175, 200) !important;
        background-color: rgb(255, 255, 255);
        border: none;
        font-size: 12px !important;
    }
    .deleteBTN{
        color: rgb(217, 5, 5) !important;
        background-color: white;
        border: none;
        font-size: 12px !important;
    }
    .ic{
        z-index: 1;
    }
    .modalStyling2{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        background-color: white;
        border: none;
        padding: 20px;
        border-radius: 5px;
       
    }

}
@media screen and (max-width:468px){
    .storesTable{
        margin-top: 4%;
        margin-left: 5%;
        width: 90%;
    }

    .storesTitle{
        font-size: 18px;
        font-weight: 500;
        color: #424242;
        text-align: left;
        
        width: 100%;
    
    }
    .addButton{
        display: flex;
        margin-bottom: 3%;
    }

    .addStoreButton{
        background-color: rgb(56, 97, 174);
        border: none;
        padding: 2px 8px 2px 8px;
        color: white;
        margin-top: 4%;
       
    }
    #standard-basic2{
        width: 300px;
    }
    .addStoreButton2{
        background-color: rgb(165, 31, 31);
        border: none;
        padding: 2px 8px 2px 8px;
        color: white;
        margin-top: 4%;
        margin-left: 6%;
    }
    .editBTN{
        color: rgb(255, 174, 24) !important;
        background-color: white;
        border: none;
        size: 3px;
        font-size: 12px !important;
    }
    .blockBTN{
        color: rgb(18, 175, 200) !important;
        background-color: rgb(255, 255, 255);
        border: none;
        font-size: 12px !important;
    }
    .deleteBTN{
        color: rgb(217, 5, 5) !important;
        background-color: white;
        border: none;
        font-size: 12px !important;
    }
    .ic{
        z-index: 1;
    }
    .modalStyling2{
        position: absolute;
        top: 50%;
        left: 50.4%;
        transform: translate(-50%, -50%);
        width: 400px;
        background-color: white;
        border: none;
        padding: 20px;
        border-radius: 5px;
       
    }
}