@media screen and (min-device-width:768px){
    .sidebarWrapper{
        height: 100vh;
        width: 240px;
       background-color: white;
       box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.75);
       -webkit-box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.75);
       -moz-box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.75);
    }
    .rights{
        margin-top: 6%;
        font-size: 9px;
    }

    .rights a{
        text-decoration: none;
    }
    .menuItem{
        display: flex;
        align-items: center;
        margin-left: 9%;
        margin-top: 3.5%;
        cursor: pointer;
        padding: 10px;
    }
    .menuItem:hover{
        background-color: rgb(239, 239, 239);
    }
    .menuItem button{
        background-color: rgba(255, 255, 255, 0);
        border: none;
        cursor: pointer;
        
        font-size: 16px;
        margin-left: 9%;
        color: rgb(66, 66, 66);
    }
}