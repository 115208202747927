@media screen and (min-device-width:768px){
    .homeWrapper{
        
    }
    .homeBody{
        display: flex;
        margin-top: 1px;
        
       
    }
    .mainBody{
        flex: 10;
        flex-direction: column;
        height: 100vh;
        overflow: scroll;
    }
    .sidebar{
        flex: 2;
        position: sticky;
    }
    
    .dashboardTitle{
        font-size: 19px;
        margin-left: 5%;
        font-weight: 500;
        text-align: start;
        color: rgb(50, 50, 50);
        
    }
    .topSats{
       
        
        
        display: flex;
        margin-left: 3%;
    }

    .box1{
        background-color: white;
        margin-left: 2%;
        width: 250px;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.75);
        -webkit-box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.75);

    }
    .monthOrders{
        text-align: start;
        margin-left: 4%;
        font-weight: 500;
        color: rgb(75, 75, 75);
       
    }
    .monthTotal{
        margin-top: -3%;
        font-size: 35px;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: 500;
        color: rgb(4, 115, 212);
    }
    .totals{
        margin-top: -5%;
        text-align: start;
        margin-left: 4%;
        color: gray;
        font-size: 13px;
    }
    .bottomStats{
        display: flex;
        margin-left: 5%;
        margin-top: 3%;
        margin-right: 3%;
        
    }
    .bottomLeft{
        flex: 9;
        margin-bottom: 5%;
    }
    .bottomRight{
        flex: 3;
        margin-top: 1.5%;
        
    }
    .overview{
        border-radius: 5px;
        width: 800px;
        height: 450px;
        background-color: white;
    }
    .overviewTop{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 3%;
        margin-right: 3%;
    }
    .overviewTop button{
        cursor: pointer;
        transition-duration: 0.4s;
        padding: 3px;
        border: none;
        background-color: rgb(243, 243, 255);
    }
    .overviewTop button:hover{
        background-color: gray;
        color: white;
    }
    .performance{
        display: flex;
        margin-top: 3%;
        margin-right: 5%;
    }
    .stores{
        border-radius: 5px;
        padding: 10px;
        flex: 6;
        background-color: white;
      
    }
    .stores p{
        text-align: start;
    }
    .riders p{
        text-align: start;
    }
    .riders{
        border-radius: 5px;
        background-color: white;
        flex: 6;
        padding: 10px;
    }
    .efficiency{
        margin-top: -5%;
        width: 320px;
        background-color: white;
    }
    .incomes{
        padding: 10px;
        position: relative;
        margin-top: 5%;
        width: 300px;
        height: 345px;
        background-color: white;
    }
    .incomes p{
        text-align: center;
        
    }
    .saleStats{
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
    .psale{
        font-size: 12px;
        color: grey;
    }
    .pAmount{
        font-size: 20px;
        font-weight: 400;
    }

    .recentActivity{
        background-color: white;
        margin-right: 5%;
        border-radius: 5px;
        margin-top: 3%;
        height: 200px;
        
    }
    .tableHead{
        display: flex;
        

        padding-left: 10px;
        padding-right: 10px;
      
        background-color: rgb(231, 231, 255);
        justify-content: space-between;
    }
    .activityTitle{
        text-align: start;
        margin-left: 3%;
        top: 5%;
    }
    .tableHead p{
        font-weight: 500;
    }
    .recentTransactions{
        background-color: white;
        width: 320px;
        height: 200px;
        margin-top: 8%;
    }
    .transactionsHead{
        display: flex;
        justify-content: space-between;
        background-color: rgb(231, 231, 255);
    }
    .transactionsHead p{
        font-weight: 500;
    }
    
}
@media screen and (max-width:468px){
    .sidebar{
        display: none;
    }
    .homeWrapper{
        
    }
    .homeBody{
        display: flex;
        margin-top: 1px;
        
       
    }
    .mainBody{
        flex: 10;
        flex-direction: column;
        height: 100vh;
        overflow: scroll;
        
        
    }
    
    .top{
        position: sticky;
    }
    .dashboardTitle{
        font-size: 19px;
        margin-left: 5%;
        font-weight: 500;
        text-align: start;
        color: rgb(50, 50, 50);
        
    }
    .topSats{
       
        
        
        display: flex;
        margin-left: 3%;
        flex-direction: column;
    }

    .box1{
        background-color: white;
        margin-left: 2%;
        margin-top: 3%;
        width:400px;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.75);
        -webkit-box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.75);

    }
    .monthOrders{
        text-align: start;
        margin-left: 4%;
        font-weight: 500;
        color: rgb(75, 75, 75);
       
    }
    .monthTotal{
        margin-top: -3%;
        font-size: 35px;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: 500;
        color: rgb(4, 115, 212);
    }
    .totals{
        margin-top: -5%;
        text-align: start;
        margin-left: 4%;
        color: gray;
        font-size: 13px;
    }
    .bottomStats{
        display: flex;
        flex-direction: column;
        margin-left: 1%;
        margin-top: 5%;
        margin-right: 3%;
        
    }
    .bottomLeft{
        flex: 9;
    }
    .bottomRight{
        flex: 3;
        
    }
    .overview{
        border-radius: 5px;
        width: 450px;
        height: 320px;
        background-color: white;
    }
    .overviewTop{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 3%;
        margin-right: 3%;
    }
    .overviewTop button{
        cursor: pointer;
        transition-duration: 0.4s;
        padding: 3px;
        border: none;
        background-color: rgb(243, 243, 255);
    }
    .overviewTop button:hover{
        background-color: gray;
        color: white;
    }
    .performance{
        display: flex;
        flex-direction: column;
     
        margin-top: 3%;
        width: 450px;
    }
    .stores{

        border-radius: 5px;
        padding: 10px;
        flex: 6;
        background-color: white;
      
    }
    .stores p{
        text-align: start;
    }
    .riders p{
        text-align: start;
    }
    .riders{
        margin-top: 3%;
        border-radius: 5px;
        background-color: white;
        flex: 6;
        padding: 10px;
    }
    .efficiency{
        margin-top: 3%;
        width: 450px;
        background-color: white;
    }
    .incomes{
        padding: 10px;
        position: relative;
        margin-top: 5%;
        width: 430px;
        height: 365px;
        background-color: white;
    }
    .incomes p{
        text-align: center;
        
    }
    .saleStats{
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
    .psale{
        font-size: 12px;
        color: grey;
    }
    .pAmount{
        font-size: 20px;
        font-weight: 400;
    }

    .recentActivity{
        background-color: white;
        margin-right: 1%;
        border-radius: 5px;
        margin-top: 3%;
        height: 200px;
        width: 450px;
        
    }
    .tableHead{
        display: flex;
        

      
        background-color: rgb(231, 231, 255);
        justify-content: space-between;
    }
    .activityTitle{
        text-align: start;
        margin-left: 3%;
        top: 5%;
    }
    .tableHead p{
        font-weight: 500;
    }
    .recentTransactions{
        background-color: white;
        width: 450px;
        height: 200px;
        margin-top: 8%;
    }
    .transactionsHead{
        display: flex;
        justify-content: space-between;
        background-color: rgb(231, 231, 255);
    }
    .transactionsHead p{
        font-weight: 500;
    }
}