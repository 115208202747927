@media screen and (min-device-width:768px){

    .ordersBody{
        flex: 10;
        display: flex;  
    }
    .ordersLeft{
        flex: 9;
      
        margin-top: 3%;
        height: 100vh;
        height: 100vh;
        overflow: scroll;
    }
    .newOrder{
        background-color: rgb(255, 0, 140);
        border: none;
        padding: 5px;
        color: white;
        text-decoration: none;
        
    }
    .newOrdercont{
        display: flex;
        margin-bottom: 3%;
    }
    .ordersRight{
        margin-left: 3%;
        margin-right: 3%;
        margin-top: 2.5%;
        flex: 3;
        
        height: 100vh;
        position: sticky;
    }
    .sidebar{
        flex: 2;
    }
    .todayDate{
        text-align: start;
        font-size: 17px;
        font-weight: 500;
        color: rgb(58, 58, 58);
    }
    .unplannedOrders{
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(255, 0, 0, 0.095);
    }
    .processingOrders{
        
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(34, 0, 255, 0.095);
    }
    .transitOrders{
        
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(0, 247, 255, 0.095);
    }
    .deliveredOrders{
        padding-bottom: 20px;
        border-radius: 5px;
        margin-bottom: 8%;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(2, 125, 17, 0.095);
    }
    .heading{
        background-color: grey !important;
        display: flex;
        justify-content: space-between;
       
    }

    .modalStyling{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        background-color: white;
        border: none;
        padding: 20px;
        border-radius: 5px;
       
    }
    .heading2{
        width: 400px;
    }
    .table{
        margin-top: 1%;
       
        
    }
    .tHeading{
        color: rgb(71, 71, 71) !important;
        font-size: 14px;
        font-weight: 500;
    }
    .tHeading2{
        color: rgb(83, 83, 83) !important;
        font-size: 14px;

        
    }
    .unplannedHeading{
        text-align: left;
        margin-left: 4%;
        color: rgb(95, 95, 95) !important;
        font-weight: 500;
    }
    .searchTitle{
        font-size: 14px;
        color: rgb(95, 95, 95) !important;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        
    }
    .search{
        align-items: center;
        display: flex;
        margin-left: 4%;
    }
    .searchInput{
        margin-left: 1%;
        border-radius: 3px;
        margin-top: -2%;
        height: 30px;
        width: 130px;
        border: 1px solid rgb(171, 171, 171);
    }
    .searchInput:focus {
        outline: none;
        border: 1px solid grey
    }
    .tbutton{
        background-color: white;
        border: none;
        font-size: 14px;
        color: rgb(71, 71, 71) !important;
    }
    .modalTitle{
        font-size: 18px;
        color: rgb(73, 73, 73);
    }
    .modalOrder{
        font-size: 14px;
        color: grey;
    }
    .riderSelect{
        color: rgb(66, 66, 66);
        border: 1px solid grey;
        height: 35px;
        font-size: 15px;
        margin-left: 2%;
        border-radius: 3px;
        width: 40%;
    }
    .riderSelect:focus{
        outline: none;
    }
    .assignButtons{
        margin-top: 8%;
        display: flex;
    }
    .assignBTN{
        background-color: rgb(6, 154, 38);
        border:none;
        color:white;
        font-size: 14px;
        padding: 5px 15px 5px 15px;
        border-radius: 3px;
    }
    .cancelBTN{
        background-color: rgb(183, 19, 19);
        border:none;
        color:white;
        font-size: 14px;
        padding: 5px 15px 5px 15px;
        border-radius: 3px;
        margin-left: 9%;
    }
    .cancelOrderBTN{
        background-color: rgb(255, 225, 56);
        border:none;
        color:white;
        font-size: 14px;
        padding: 5px 15px 5px 15px;
        border-radius: 3px;
        margin-left: 9%;
    }
    .assignBTN:hover{
        background-color: rgb(2, 123, 28);
    }
    .error{
        font-size: 14px;
        color: red;
    }
    .totalOrdersBox{
        margin-top: 2%;
        padding: 10px;
        background-color: white;
    }
    .orderActivity{
        display: flex;
        justify-content: space-between;
    }
    .tOrders{
        text-align: left;
        font-weight: 500;
        color: rgb(75, 75, 75);
    }
    .aOrders{
        color: gray;
        text-align: left;
        font-size: 14px;
    }
    .dispatchContainer{
        padding: 20px;
    }

}

@media screen and (max-width:468px){
    .ordersBody{
        flex: 10;
        display: flex;
        flex-direction: column;
       
      
    }
    .newOrder{
        background-color: rgb(255, 0, 140);
        border: none;
        padding: 5px;
        color: white;
        text-decoration: none;
        
    }
    .newOrdercont{
        margin-left: 1%;
        display: flex;
        margin-bottom: 3%;
    }
    .ordersLeft{
        flex: 9;
        margin-top: 3%;
        height: 100vh;

    }
    .ordersRight{
        margin-left: 3%;
        margin-right: 3%;
        margin-top: 6%;
        flex: 3;
        
   
    }
    .sidebar{
        flex: 2;
    }
    .todayDate{
        text-align: start;
        font-size: 17px;
        font-weight: 500;
        color: rgb(58, 58, 58);
    }
    .unplannedOrders{
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(255, 0, 0, 0.095);
    }
    .processingOrders{
        
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(34, 0, 255, 0.095);
    }
    .transitOrders{
        
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(0, 247, 255, 0.095);
    }
    .deliveredOrders{
        padding-bottom: 20px;
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(2, 125, 17, 0.095);
    }
    .heading{
        background-color: grey !important;
        display: flex;
        justify-content: space-between;
       
    }
    .heading2{
        width: 400px;
    }
    .table{
        margin-top: 1%;
       
        
    }
    .tHeading{
        color: rgb(71, 71, 71) !important;
        font-size: 14px;
        font-weight: 500;
    }
    .tHeading2{
        color: rgb(83, 83, 83) !important;
        font-size: 14px;

        
    }
    .unplannedHeading{
        text-align: left;
        margin-left: 4%;
        color: rgb(95, 95, 95) !important;
        font-weight: 500;
    }
    .searchTitle{
        font-size: 14px;
        color: rgb(95, 95, 95) !important;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        
    }
    .search{
        align-items: center;
        display: flex;
        margin-left: 4%;
    }
    .searchInput{
        margin-left: 1%;
        border-radius: 3px;
        margin-top: -2%;
        height: 30px;
        width: 130px;
        border: 1px solid rgb(171, 171, 171);
    }
    .searchInput:focus {
        outline: none;
        border: 1px solid grey
    }
    .tbutton{
        background-color: white;
        border: none;
        font-size: 14px;
        color: rgb(71, 71, 71) !important;
    }
    .modalTitle{
        font-size: 18px;
        color: rgb(73, 73, 73);
    }
    .modalOrder{
        font-size: 14px;
        color: grey;
    }
    .riderSelect{
        color: rgb(66, 66, 66);
        border: 1px solid grey;
        height: 35px;
        font-size: 15px;
        margin-left: 2%;
        border-radius: 3px;
        
    }
    .riderSelect:focus{
        outline: none;
    }
    .assignButtons{
        margin-top: 8%;
        display: flex;
    }
    .assignBTN{
        background-color: rgb(6, 154, 38);
        border:none;
        color:white;
        font-size: 14px;
        padding: 5px 15px 5px 15px;
        border-radius: 3px;
    }
    .cancelBTN{
        background-color: rgb(183, 19, 19);
        border:none;
        color:white;
        font-size: 14px;
        padding: 5px 15px 5px 15px;
        border-radius: 3px;
        margin-left: 9%;
    }
    .cancelOrderBTN{
        background-color: rgb(255, 225, 56);
        border:none;
        color:white;
        font-size: 14px;
        padding: 5px 15px 5px 15px;
        border-radius: 3px;
        margin-left: 9%;
    }
    .assignBTN:hover{
        background-color: rgb(2, 123, 28);
    }
    .error{
        font-size: 14px;
        color: red;
    }
    .totalOrdersBox{
        margin-top: 2%;
        width: 370px;
        padding: 10px;
        background-color: white;
    }
    .orderActivity{
        display: flex;
        justify-content: space-between;
    }
    .tOrders{
        text-align: left;
        font-weight: 500;
        color: rgb(75, 75, 75);
    }
    .aOrders{
        color: gray;
        text-align: left;
        font-size: 14px;
    }
    .modalStyling{
        position: absolute;
        top: 30%;
        left: 32%;
        transform: translate(-50%, -50%);
        width: 400px;
        background-color: white;
        border: none;
        padding: 20px;
        border-radius: 5px;
       
    }
}