.userselect{
    color: gray;
    padding: 8px;
    margin-top: 7%;
    border: 1px solid gray;
    border-radius: 5px;
  
}
.userselect:focus{
    outline: none;
    border: 1px solid rgb(0, 183, 255);
}
