@media screen and (min-device-width:768px){
    .unplannedOrders3{
        width: 1400px;
        margin-left: -4%;
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(255, 0, 0, 0.095);
    }
    .calender{
        margin-left: -42%;
    }
    .inputDate1{
        padding: 5px;
        color: gray;
        border: 1px solid gray;
        border-radius: 3px;
        margin: 5px;
    }

    .inputDate1:focus{
        outline: none;
    }
    .tHeading3{
        font-size: 12px !important;
    }
    .downloadBtn{
        padding: 5px;
        margin: 10px;
        border-radius: 5px;
        color: white;
        border: none;
        background-color: rgb(90, 150, 197);
    }
    .downloadBtn:hover{
        background-color: rgb(44, 114, 168);
    }
}

@media screen and (max-width:468px){
    .unplannedOrders3{
        width: 1400px;
        margin-left: -4%;
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        background-color: rgba(255, 0, 0, 0.095);
    }
    .calender{
        margin-left: -42%;
    }
    .inputDate1{
        padding: 5px;
        color: gray;
        border: 1px solid gray;
        border-radius: 3px;
        margin: 5px;
    }

    .inputDate1:focus{
        outline: none;
    }
    .tHeading3{
        font-size: 12px !important;
    }
    .downloadBtn{
        padding: 5px;
        margin: 10px;
        border-radius: 5px;
        color: white;
        border: none;
        background-color: rgb(90, 150, 197);
    }
    .downloadBtn:hover{
        background-color: rgb(44, 114, 168);
    }
}