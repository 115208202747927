@media screen and (min-device-width:768px){
    .settingsHeader{
        margin-left: 3%;
        margin-top: 8%;
        color: gray;
        font-size: 14px;
    }
    .settingsBox{
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        margin-top: 10%;
        margin-left: 27%;
        height: 100px;
        width: 500px;
        background-color: white;
    }
}
@media screen and (max-width:468px){
    .settingsHeader{
        margin-left: 3%;
        margin-top: 8%;
        color: gray;
        font-size: 14px;
    }
    .settingsBox{
        border-radius: 5px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        margin-top: 20%;
        margin-left: 20%;
        height: 100px;
        width: 300px;
        background-color: white;
    }
}